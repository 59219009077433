<template>
  <div class="new-transaction">
    <h2 class="title">{{ $t('transactions.editTitle') }}</h2>

    <div v-if="error" class="notification is-danger">
      {{ error }}
    </div>

    <account-book-form
            v-if="accountBook"
      :account-book="accountBook"
      :submit-callback="update"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import AccountBookForm from './AccountBookForm'

export default {
  name: 'edit-transaction',
  components: {
    AccountBookForm
  },
  data () {
    return {
      accountBook: null,
      error: null
    }
  },
  created () {
    this.loadAccountBook()
  },
  computed: {
    ...mapState({
      user: state => state.user
    })
  },
  methods: {
    async update () {
      try {
        await this.$store.dispatch('accountBooks/update', this.accountBook)
      } catch (error) {
        this.error = error.response.data.message
      }

      this.$router.push('/')
    },
    async loadAccountBook () {
      const response = await this.$store.dispatch('accountBooks/get', this.$route.params.slug)
      this.accountBook = response.data
    }
  }
}
</script>

<style scoped>

</style>
